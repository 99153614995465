// React Required
import React, {Component} from 'react';
import ReactDOM from 'react-dom';
// Create Import File
import './index.scss';
// Home layout
import PortfolioLanding from './home/PortfolioLanding';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
// import * as serviceWorker from './serviceWorker';/
import error404 from "./elements/error404";
import Yoga from "./home/Yoga";
import Renforcement from "./home/Renforcement";
import Stretching from "./home/Stretching";
import Pilates from "./home/Pilates";
import Danse from "./home/Danse";
import MentionsLegales from "./home/MentionsLegales";
import Cgu from "./home/Cgu";
import Cgv from "./home/Cgv";

class Root extends Component {
    render(){
        return(
            <BrowserRouter basename={'/'}>
                <Switch>
                    <Route exact path="/" component={PortfolioLanding}/>
                    <Route exact path="/yoga" component={Yoga}/>
                    <Route exact path="/renforcement" component={Renforcement}/>
                    <Route exact path="/stretching" component={Stretching}/>
                    <Route exact path="/pilates" component={Pilates}/>
                    <Route exact path="/danse" component={Danse}/>
                    <Route exact path="/mentions" component={MentionsLegales}/>
                    <Route exact path="/cgu" component={Cgu}/>
                    <Route exact path="/cgv" component={Cgv}/>
                    <Route path="/404" component={error404}/>
                    <Route component={error404}/>
                </Switch>
            </BrowserRouter>
        )
    }
}

ReactDOM.render(<Root/>, document.getElementById('root'));
// ReactDOM.render(<PortfolioLanding/>, document.getElementById('root'));
// serviceWorker.register();

navigator.serviceWorker.getRegistrations().then(registrations => {
    for (const registration of registrations) {
        registration.unregister();
    }
});